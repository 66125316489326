import React from "react";
import * as Sentry from "@sentry/nextjs";
import ErrorPageBody from "../components/ErrorPageBody/ErrorPageBody";
import { NextPage } from "next";

const Error: NextPage = () => (
  <>
    <div className="min-w-screen min-h-screen w-full h-full flex justify-center items-center text-white">
      <ErrorPageBody />
    </div>
  </>
);

Error.getInitialProps = ({ res, err }) => {
  if (err && err.statusCode !== 404) {
    Sentry.captureException(err);
  }
  const statusCode = (() => {
    if (res) {
      return res.statusCode;
    }

    return err ? err.statusCode : 404;
  })();
  return { statusCode };
};

export default Error;
