import { useRouter } from "next/router";
import React from "react";
import Logo from "../../assets/logo.svg";

const ErrorPageBody = () => {
  const router = useRouter();
  return (
    <div className="flex flex-col items-center">
      <div onClick={() => router.push("/")} className="cursor-pointer">
        <Logo />
      </div>
      <p className="text-head pt-12 font-formula font-semibold">
        Page not found
      </p>
    </div>
  );
};

export default ErrorPageBody;
